import * as React from "react"
import { Grid, GridCell } from "@rmwc/grid"
import { Card } from "../../components/Card"
import { Headline2 } from "../../components/rmwc/Typography"
import { LinearProgress } from "../../components/rmwc/Progress"
import { Form } from "../../components/Form"
// import { RouteComponentProps, Redirect } from 'react-router-dom'
import { ProgressIndicator } from "../../pages/signup"
import { TextField } from "../../components/rmwc/TextField"
import { RouteComponentProps, Redirect } from "@reach/router"
import { useCurrentUser } from "../../hooks/useCurrentUser"
import useMutation from "../../hooks/useMutation"

interface Props {}

const SignupAddress: React.FC<
  Props & RouteComponentProps<{ id: string; path: string }>
> = props => {
  const [street, setstreet] = React.useState("")
  const [apt, setapt] = React.useState("")
  const [city, setcity] = React.useState("")
  const [zip, setzip] = React.useState("")
  const [state, setstate] = React.useState("")
  const id = props.id
  const { currentUserContext } = useCurrentUser()

  const [addressCreated, setAddressCreated] = React.useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const { success, error } = useMutation({
      collection: "addresses",
      add: {
        id,
        uid: id,
        street,
        apt,
        city,
        zip,
        state,
      },
    })

    if (success) {
      setAddressCreated(true)
    }
  }

  if (addressCreated) {
    return <Redirect to={`/signup/payment/${id}`} noThrow />
  }

  return (
    <Grid>
      <GridCell span={3} />
      <GridCell span={6}>
        <Card title="Shipping addres!!s">
          <ProgressIndicator>
            <Headline2>Step 2 of 3</Headline2>
            <LinearProgress progress={0.66} />
          </ProgressIndicator>
          <Form
            disabled={false}
            onSubmit={handleSubmit}
            submitText="Create address"
            // message={errors}
            messageType={"error"}
            active={true}
          >
            <TextField state={[apt, setapt]} label="Apt" />
            <TextField state={[street, setstreet]} label="Street" required />
            <TextField state={[city, setcity]} label="City" required />
            <TextField state={[state, setstate]} label="State" required />
            <TextField
              state={[zip, setzip]}
              label="Zip"
              pattern="[0-9]*"
              required
            />
          </Form>
        </Card>
      </GridCell>
    </Grid>
  )
}

export default SignupAddress

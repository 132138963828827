import * as React from 'react'
import {
  LinearProgress as MaterialLinearProgress,
  LinearProgressProps,
} from '@rmwc/linear-progress'
import '@material/linear-progress/dist/mdc.linear-progress.css'

export const LinearProgress: React.FC<LinearProgressProps> = props => {
  return <MaterialLinearProgress {...props} />
}

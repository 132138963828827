import * as React from "react"
import { Redirect, Router, Link } from "@reach/router"
import styled from "styled-components"
import EmailForm from "../views/signup/EmailForm"
import AddressForm from "../views/signup/AddressForm"
import PaymentForm from "../views/signup/PaymentForm"

export const ProgressIndicator = styled.div`
  margin-bottom: 24px;
`

const Signup = () => {
  return (
    <Router>
      <EmailForm path="/signup" />
      <AddressForm path="/signup/address/:id" />
      <PaymentForm path="/signup/payment/:id" />
    </Router>
  )
}

export default Signup

import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primary};
  /* background-image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`

export function FullBleed(props) {
  const { children } = props

  return <Wrapper>{children}</Wrapper>
}

import * as React from "react"
import { RouteComponentProps, Redirect } from "@reach/router"
import { Grid, GridCell } from "@rmwc/grid"
import { FullBleed } from "../../components/FullBleed"
import { Card } from "../../components/Card"
import { Headline2 } from "../../components/rmwc/Typography"
import { LinearProgress } from "../../components/rmwc/Progress"
import { Form } from "../../components/Form"
import { ProgressIndicator } from "../../pages/signup"
import { Container } from "../../components/Container"
import { CardElement, Elements, StripeProvider } from "react-stripe-elements"
import { useCurrentUser } from "../../hooks/useCurrentUser"
import { useStripe, StripeHookProvider } from "../../hooks/StripeHookProvider"
import useFirestore from "../../hooks/useFirestore"
import useMutation from "../../hooks/useMutation"

const PaymentForm: React.FC<
  RouteComponentProps<{ id: string; path: string }>
> = props => {
  const { id } = props
  const { data: user, loading } = useFirestore({
    collection: "users",
    find: id,
  })

  if (loading) return null

  return (
    <FullBleed>
      <Container>
        <Grid>
          <GridCell span={6} />
          <GridCell span={6}>
            <Card title="Payment info">
              <ProgressIndicator>
                <Headline2>Step 3 of 3</Headline2>
                <LinearProgress progress={1} />
              </ProgressIndicator>

              {user && <CreateStripeAccount user={user} />}
            </Card>
          </GridCell>
        </Grid>
      </Container>
    </FullBleed>
  )
}

interface CreateStripeAccountProps {
  user: any
}

const CreateStripeAccount: React.FC<CreateStripeAccountProps> = props => {
  const [error, setError] = React.useState("")
  const [stripeAccountCreated, setStripeAccountCreated] = React.useState(false)
  const stripe = useStripe()
  const { user } = props
  const { currentUserContext } = useCurrentUser()

  // const [createStripe, { loading }] = useMutation(CREATE_STRIPE)

  const handleSubmit = async event => {
    event.preventDefault()
    const stripeResponse = await stripe.createToken()
  }

  if (stripeAccountCreated) {
    return <Redirect to={`/users/profile/${user.id}`} noThrow />
  }

  return (
    <Form
      disabled={false}
      onSubmit={handleSubmit}
      submitText="Create payment"
      message={error}
      messageType={"error"}
      active={true}
    >
      <CardElement />
    </Form>
  )
}

export default PaymentForm

import * as React from "react"
import { TextField } from "../../components/rmwc/TextField"
import { auth } from "../../core/firebase"
import { FullBleed } from "../../components/FullBleed"
import { Card } from "../../components/Card"
import { Grid, GridCell } from "@rmwc/grid"
import { Form } from "../../components/Form"
import { Headline2 } from "../../components/rmwc/Typography"
import { LinearProgress } from "../../components/rmwc/Progress"
import { ProgressIndicator } from "../../pages/signup"
import { Link, Redirect, RouteComponentProps } from "@reach/router"
import { useCurrentUser } from "../../hooks/useCurrentUser"

const Signup: React.FC<RouteComponentProps> = props => {
  const { currentUser } = useCurrentUser()
  const [loading, setLoading] = React.useState(false)
  const [name, setName] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [error, setError] = React.useState("")
  const [userCreated, setUserCreated] = React.useState(false)

  const handleSignup = async event => {
    event.preventDefault()
    setLoading(true)

    auth
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        const { user } = result
        user.updateProfile({
          displayName: name,
          phoneNumber: phone,
        })
      })
      .catch(error => setError(error.message))
    setLoading(false)
  }

  if (currentUser) {
    return <Redirect to={`/signup/address/${currentUser.id}`} noThrow />
  }

  return (
    <Grid>
      <GridCell span={6} />
      <GridCell span={6}>
        <Card title="Create account">
          <ProgressIndicator>
            <Headline2>Step 1 of 3</Headline2>
            <LinearProgress progress={0.33} />
          </ProgressIndicator>
          <Form
            disabled={loading}
            onSubmit={handleSignup}
            submitText="Sign up"
            message={error}
            messageType={"error"}
            active={true}
          >
            <TextField state={[name, setName]} label="Name" required />
            <TextField
              state={[phone, setPhone]}
              label="Phone number"
              type="tel"
              pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
              required
            />
            <TextField
              state={[email, setEmail]}
              label="Email address"
              type="email"
              required
            />
            <TextField
              state={[password, setPassword]}
              label="Password"
              type="password"
              required
            />
          </Form>
          <br />
          <div>
            <Link to="/signin">Already have an account?</Link>
          </div>
        </Card>
      </GridCell>
    </Grid>
  )
}

export default Signup
